<script lang="ts">
  import { onMount } from 'svelte';
  import { t } from 'svelte-i18n';
  import { Route, Router } from 'svelte-routing';
  import { get } from 'svelte/store';
  import EmptyScreen from './lib/components/EmptyScreen.svelte';
  import { hideLoader } from './lib/JSBridge/loaderController';
  import BankSelection from './lib/routes/BankSelection.svelte';
  import BankSelectionType from './lib/routes/BankSelectionType.svelte';
  import Home from './lib/routes/Home.svelte';
  import Invoice from './lib/routes/Invoice.svelte';
  import InvoiceDetails from './lib/routes/InvoiceDetails.svelte';
  import Repayment from './lib/routes/Repayment.svelte';
  import { isAndroid } from './lib/utils/detectPlatform';
  import { routes } from './routes';
  import { settingsStore } from './store/settingsStore';

  export let url = '';
  export let hasAuthError = false;

  const settings = get(settingsStore);

  onMount(() => {
    import('pdfjs-dist/build/pdf.worker.mjs');

    if (!isAndroid()) {
      document.body.classList.add('ios');
    }

    // hiding the loader triggered in main.ts file
    hideLoader();
  });
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="true" />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
    rel="stylesheet"
  />
</svelte:head>

{#if import.meta.env.MODE !== 'test'}
  <!-- Hide the app for screens > 767px -->
  <div
    class="fixed inset-0 z-[100] hidden h-screen w-screen bg-white min-[768px]:block"
  >
    <EmptyScreen label={$t('error.device')} />
  </div>
{/if}

<Router {url}>
  <Route path={routes.home.path} component={Home} {hasAuthError} />
  <Route path={routes.mcredit.path} component={Home} />
  <Route
    path={routes.repaymentStatus.path + `/:${settings.repaymentStatus}`}
    component={Home}
  />
  <Route path={routes.invoice.path + '/:id'} let:params>
    <Invoice id={params.id} />
  </Route>
  <Route path={routes.invoiceDetails.path + '/:id'} let:params>
    <InvoiceDetails id={params.id} />
  </Route>
  <Route path={routes.repayment.path} component={Repayment} />
  <Route path={routes.repaymentBankSelection.path} component={BankSelection} />
  <Route path={routes.repaymentBankSelectionType.path + '/:bank'} let:params>
    <BankSelectionType bank={params.bank} />
  </Route>
</Router>
