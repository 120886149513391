<script lang="ts">
  import { onMount } from 'svelte';
  import { t } from 'svelte-i18n';
  import { navigate } from 'svelte-routing';
  import { quintOut } from 'svelte/easing';
  import { fade, fly } from 'svelte/transition';
  import { posthog } from '../../plugins/posthog';
  import { routes } from '../../routes';
  import { settingsStore } from '../../store/settingsStore';
  import { topBar } from '../../store/TopBarStore';
  import { bodyScrollLock } from '../../store/UiStore';
  import Dialog from '../components/Dialog.svelte';
  import EmptyScreen from '../components/EmptyScreen.svelte';
  import Invoices from '../components/Invoices.svelte';
  import MCredit from '../components/MCredit.svelte';
  import MCreditInvoices from '../components/MCreditInvoices.svelte';
  import NavigationTabs from '../components/NavigationTabs.svelte';
  import SpendGraphSection from '../components/SpendGraphSection.svelte';
  import AnimatedBlueBackdrop from '../layouts/animatedBlueBackdrop.svelte';
  import Layout from '../layouts/main.svelte';
  import { isIosWebView } from '../utils/detectPlatform';

  export let hasAuthError = false;

  let showRepaymentDialog = false;
  let dialogTitle = '';
  let dialogDescription = '';

  const leftToRightDelayTrans = {
    delay: 500,
    duration: 500,
    easing: quintOut,
    opacity: 0,
    x: -300,
  };

  const rightToLeftDelayTrans = {
    delay: 500,
    duration: 500,
    easing: quintOut,
    opacity: 0,
    x: 300,
  };

  const leftToRightTrans = {
    delay: 0,
    duration: 500,
    easing: quintOut,
    opacity: 0,
    x: 300,
  };

  const REPAYMENT_STATUS_CONFIG = {
    completed: {
      description: 'repayment_status_dialog.success.description',
      title: 'repayment_status_dialog.success.title',
    },
    error: {
      description: 'repayment_status_dialog.error.description',
      title: 'repayment_status_dialog.error.title',
    },
  };

  posthog.capture('$pageview');

  onMount(() => {
    topBar.setShowBackButton(isIosWebView());

    syncActiveTabWithUrl();
    handleRepaymentStatus();

    // listen for browser navigation to update active tab (+ cleanup on component destroy)
    window.addEventListener('popstate', syncActiveTabWithUrl);
    return () => {
      window.removeEventListener('popstate', syncActiveTabWithUrl);
    };
  });

  function syncActiveTabWithUrl() {
    if (
      $settingsStore.accountDetails.mcreditAccess &&
      window.location.pathname.includes('mcredit')
    ) {
      settingsStore.setActiveTab(2);
    } else {
      settingsStore.setActiveTab(1);
    }
  }

  function handleRepaymentStatus() {
    const repaymentStatus = $settingsStore.repaymentStatus;

    if (!repaymentStatus) {
      return;
    }

    if (repaymentStatus === 'completed') {
      showRepaymentDialog = true;
      dialogTitle = REPAYMENT_STATUS_CONFIG.completed.title;
      dialogDescription = REPAYMENT_STATUS_CONFIG.completed.description;
      return;
    }

    if (['failed', 'revoked'].includes(repaymentStatus)) {
      showRepaymentDialog = true;
      dialogTitle = REPAYMENT_STATUS_CONFIG.error.title;
      dialogDescription = REPAYMENT_STATUS_CONFIG.error.description;
      return;
    }
  }

  function onAnimationStart() {
    bodyScrollLock.set(true);
  }

  function onAnimationEnd() {
    bodyScrollLock.set(false);
  }

  function redirectToMCredit() {
    showRepaymentDialog = false;
    settingsStore.setRepaymentStatus('');
    navigate(routes.mcredit.path);
  }
</script>

<Layout>
  {#if hasAuthError}
    <div class="mt-6">
      <EmptyScreen label={$t('error.auth_failure')} />
    </div>
  {:else}
    {#if $settingsStore.accountDetails.mcreditAccess}
      <NavigationTabs />
    {/if}

    <AnimatedBlueBackdrop>
      {#if $settingsStore.activeTab === 1}
        <div
          class={[
            'transition-all duration-300',
            $settingsStore.isGraphExpanded ? 'h-[88px]' : 'h-[72px]',
          ].join(' ')}
          in:fly={leftToRightDelayTrans}
          out:fade={{ duration: 500 }}
          on:introend={onAnimationEnd}
          on:outrostart={onAnimationStart}
        >
          <SpendGraphSection />
        </div>
      {:else}
        <div
          in:fly={rightToLeftDelayTrans}
          out:fly={leftToRightTrans}
          on:introend={onAnimationEnd}
          on:outrostart={onAnimationStart}
        >
          <MCredit />
        </div>
      {/if}
    </AnimatedBlueBackdrop>

    {#if $settingsStore.activeTab === 1}
      <div
        class={[
          $settingsStore.accountDetails.mcreditAccess
            ? 'pt-[191px]'
            : 'pt-[143px]',
        ].join(' ')}
        in:fly={leftToRightDelayTrans}
        out:fade={{ duration: 500 }}
        on:introend={onAnimationEnd}
        on:outrostart={onAnimationStart}
      >
        <Invoices />
      </div>
    {/if}

    {#if $settingsStore.accountDetails.mcreditAccess && $settingsStore.activeTab === 2}
      <div
        in:fly={rightToLeftDelayTrans}
        out:fade={{ duration: 500 }}
        on:introend={onAnimationEnd}
        on:outrostart={onAnimationStart}
      >
        <MCreditInvoices />
      </div>
    {/if}
  {/if}
</Layout>

<Dialog
  bind:showDialog={showRepaymentDialog}
  title={dialogTitle}
  description={dialogDescription}
  primaryButtonLabel="repayment_status_dialog"
  onPrimaryClick={[redirectToMCredit]}
  showCancelButton={false}
  shouldNavigateBack={false}
  plainIcon
  centerContent
  iconName={showRepaymentDialog && dialogTitle.includes('success')
    ? 'Success'
    : 'Error'}
/>
