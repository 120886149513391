import { Context } from './lib/utils/analytics/keys';

export const routes = {
  home: {
    name: Context.Home,
    path: '/',
  },
  invoice: {
    name: Context.Invoice,
    path: '/invoice',
  },
  invoiceDetails: {
    name: Context.InvoiceDetails,
    path: '/invoice-details',
  },
  mcredit: {
    name: Context.Mcredit,
    path: '/mcredit',
  },
  repayment: {
    name: Context.Repayment,
    path: '/repayment',
  },
  repaymentBankSelection: {
    name: Context.BankSelection,
    path: '/repayment/bank-selection',
  },
  repaymentBankSelectionType: {
    name: Context.BankSelectionType,
    path: '/repayment/bank-selection', // same as above but with passed /:bank param
  },
  repaymentStatus: {
    name: Context.Mcredit,
    path: '/mcredit/repayment-status',
  },
};
